import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Code snippets`}</em>{` are small blocks of reusable code that can be inserted in a code file.`}</p>
    <p>{`Our code snippets are built with basic styling. We recommend using `}<a parentName="p" {...{
        "href": "https://github.com/chriskempson/base16"
      }}>{`Base16`}</a>{` for more complex syntax highlighting. Base16 provides carefully chosen syntax highlighting and a default set of sixteen colors suitable for a wide range of applications. The theme we recommend using is `}<strong parentName="p">{`Solarflare`}</strong>{`.`}</p>
    <h4 {...{
      "id": "overflow"
    }}>{`Overflow`}</h4>
    <p>{`If there are more than nine lines of code, apply vertical scrolling to the code snippet.`}</p>
    <p>{`Terminal commands are often longer strings and should only appear on one line. Apply horizontal scrolling to maintain the set width of the box for those longer strings.`}</p>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <p>{`Code snippets are accompanied by a `}<strong parentName="p">{`copy icon`}</strong>{`, which allows users to copy the provided code to their clipboard. The copy icon must be accompanied by a confirmation that states the successful action of copying an item to the users clipboard.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.55434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABiklEQVQoz6VSS04CQRDthlO4cufeA3AIz6ArlxyCzEZhAXoK48jKTBw37Ax7QoAQ/uH/nxnoHspXDaLjwphQSaV6prvee/UR4p9GRGI6nYp2u23Of1omkxEnmPwiSCQSQgyHwxh/gP222+1+dDodZzweu4huv993B4OBO5lMXM/z3PV67S6XS/a37Xb7Xq1WXwF2xvm4lwYVyXGO8/n8QSlFtVqNRqMRAZBQHrVaLQIpzWYzWq1WxG/YCoUCZbNZSiaTN5yfy+ViRuFisTCAYL/3fZ/AGgBUVyoVDTANYA3lJrJDgIZCVSwWleM4lEqlrjg/n8/vAXEZP0i+g7NCv1wuq1KppHq9noJyBbKjQ6ViQFi42+08iL04DG1f8g+Fj1xKvV4n/IuU99vQP9Ja02azISi+5Hy05KjQDAUA19z8ZrP5hLMNtTbubIBHIhJtkD43Go0XvOe354cZyMiOnWIg//4As4lhGAr0TWInJcqQYJa4izivBgYjLcuS6XRaYoAyCAKTj00w8ROA4abeakBt8AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "code snippet example",
            "title": "code snippet example",
            "src": "/static/c86dee9478849fcc5062dd853652e4ae/fb070/code-snippet-usage-1.png",
            "srcSet": ["/static/c86dee9478849fcc5062dd853652e4ae/d6747/code-snippet-usage-1.png 288w", "/static/c86dee9478849fcc5062dd853652e4ae/09548/code-snippet-usage-1.png 576w", "/static/c86dee9478849fcc5062dd853652e4ae/fb070/code-snippet-usage-1.png 1152w", "/static/c86dee9478849fcc5062dd853652e4ae/c3e47/code-snippet-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      